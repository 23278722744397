<template>
  <form action="" @submit.prevent="formSubmit">
    <div class="max-w-2xl mt-8 mx-auto p-4 bg-white rounded">
      <label for="" class="text-2xl">Role Assign</label>
      <div class="text-left p-2">
        <div class="text-left p-2 relative">
          <label for="">Find User</label>
          <Input
            v-if="!user"
            type="number"
            v-model="phone"
            @input="onChange"
            class="w-full py-2 p-2 focus:outline-none"
            placeholder="Phone Number..."
          />
          <Input
            v-else
            :value="user.name"
            v-model="form.user_id"
            class="w-full py-2 p-2 focus:outline-none"
          />
        </div>
      </div>

      <div class="text-left p-2">
        <label for="">Role Name</label>
        <div class="grid grid-cols-3 gap-2">
          <div class="flex items-center" v-for="role in roles" :key="role.id">
            <input
              id="checkbox"
              type="checkbox"
              v-model="form.role"
              :value="role.id"
              class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600"
            />
            <label
              for="checkbox"
              class="ml-2 text-sm font-medium text-gray-900"
              >{{ role.name }}</label
            >
          </div>
        </div>
      </div>

      <div class="flex justify-between mt-4">
        <div
          @click="$router.go(-1)"
          class="text-brand rounded-md py-1.5 px-2 cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7 16l-4-4m0 0l4-4m-4 4h18"
            />
          </svg>
        </div>
        <Btn type="submit"> Submit </Btn>
      </div>
    </div>
  </form>
</template>
<script>
import Select from "../../../components/select.vue";
import Btn from "../../../components/btn.vue";
import Input from "../../../components/input-new.vue";
export default {
  components: {
    Select,
    Btn,
    Input,
  },
  data() {
    return {
      form: {
        role: [],
        user_id: "",
      },
      phone: "",
    };
  },
  created() {
    this.$store.dispatch("roles/roleList");
  },
  computed: {
    roles() {
      return this.$store.state.roles.roles_list;
    },
    findUser() {
      return this.$store.state.adminAppointment.findUser || "";
    },
    user() {
      const user = this.findUser.user || "";
      if (user) {
        this.form.user_id = user.id;
        this.findUser.user = "";
        return user;
      }
    },
  },
  methods: {
    formSubmit() {
      this.$store
        .dispatch("roleAssign/dataSubmit", {
          data: this.form,
        })
        .then(() => {
          this.$toast.success(`Role Assign successfully created !!!`, {
            position: "top",
            duration: 3000,
          });
          this.$router.replace({
            path: "/admin/role-assign",
          });
        });
    },
    onChange() {
      if (this.phone.length > 10) {
        this.$store.dispatch("adminAppointment/findUser", {
          phone: this.phone,
        });
      }
    },
  },
};
</script>
